import { graphql } from "gatsby"
import React from "react"
import styled from "@emotion/styled"
import { Box, Text } from "theme-ui"

import Layout from "../components/layouts"
import ContentModules from "../components/Page/ContentModules"
import SEO from "../components/seo"
import { isMobileWindow } from "../utils/render-util"
import useWindowSize from "../hooks/useWindowSize"

const Container = styled.div`
  position: relative;

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    background-color: ${(props) => props.theme.colors.grayscale[100]};
    padding-top: 50px;
  }
`

const ResponsibilityPageTemplate = ({ data, pageContext }) => {
  let { page } = data
  const windowSize = useWindowSize()
  const [headings, setHeadings] = React.useState([
    {
      id: "___gatsby",
      title: "Responsibility at Tekla",
      positionY: 0,
    },
    {
      id: "2a05e68c-647a-4566-a79c-0ed5bab94d33",
      title: "2023-2024 update",
      positionY: 0,
    },
    {
      id: "479f2a74-8173-487e-a8d4-dc575a208e27",
      title: "Highlights",
      positionY: 0,
    },
    {
      id: "d1fae63c-96a7-49ef-a84a-072828dd6278",
      title: "B Corp",
      positionY: 0,
    },
    {
      id: "4192f2fc-f281-454a-87fd-c850c909b5e2",
      title: "Read more",
      positionY: 0,
    },
  ])
  const [currentHeading, setCurrentHeading] = React.useState(null)

  const handleAnchorNavigationItemClick = (event) => {
    const element = document.getElementById(event.target.dataset.elementId)
    const offset = isMobileWindow(windowSize.width) ? -100 : -55

    if (element) {
      window.scrollTo({
        top: element?.getBoundingClientRect()?.top + window.scrollY + offset,
        left: 0,
        behavior: "smooth",
      })
    }
  }

  React.useLayoutEffect(() => {
    function handleResize() {
      const nextHeadings = headings.map((heading) => {
        return {
          ...heading,
          positionY:
            window.scrollY +
            document.getElementById(heading.id)?.getBoundingClientRect().y,
        }
      })

      setHeadings(nextHeadings)
    }

    window.addEventListener("resize", handleResize)

    handleResize()

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  React.useLayoutEffect(() => {
    function handleScroll() {
      const scrollPosition = window.scrollY + window.innerHeight / 3

      const currentHeading = headings.reduce((prev, curr) => {
        return Math.abs(curr.positionY - scrollPosition) <
          Math.abs(prev.positionY - scrollPosition)
          ? curr
          : prev
      })

      setCurrentHeading(currentHeading.id)
    }

    window.addEventListener("scroll", handleScroll)

    handleScroll()

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [headings])

  return (
    <Layout
      pullUp={page.isNavbarOverlapping}
      hidePromotional={page.hidePromotionalElement}
      isSizeGuide={page.pageTitle === "Size Guide" ? true : false}
    >
      <SEO
        metaImage={page.metaImage && page.metaImage.file.url}
        title={page.pageTitle}
        description={page.metaDescription?.metaDescription}
      />
      <Container>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "sticky",
            top: 0,
            left: 0,
            zIndex: "anchorNavigation",
            backgroundColor: "grayscale.white",
            borderBlock: "1px solid",
            borderBlockColor: "grayscale.300",
            paddingBlockStart: 0,
            "@media (min-width: 1200px)": {
              width: "calc((100vw - 768px) / 2)",
              position: "absolute",
              backgroundColor: "transparent",
              borderBlockColor: "transparent",
              paddingBlockStart: 13,
              paddingBlockEnd: 15,
            },
          }}
        >
          <Box
            as="ul"
            sx={{
              display: "flex",
              gap: 4,
              alignItems: "center",
              width: "100%",
              height: 17,
              listStyleType: "none",
              zIndex: 1000,
              overflowX: "scroll",
              paddingInline: 4,
              marginBlock: 0,
              marginInline: "auto",
              marginBlockStart: 0,
              "@media (min-width: 1200px)": {
                display: "block",
                width: 35,
                height: "auto",
                position: "sticky",
                top: 15,
                overflowX: "visible",
                paddingInline: 0,
                marginBlockStart: 15,
              },
            }}
          >
            {headings.map((heading) => {
              return (
                <Box
                  as="li"
                  key={heading.id}
                  data-element-id={heading.id}
                  onClick={handleAnchorNavigationItemClick}
                  sx={{
                    minWidth: "auto",
                    fontSize: "sm",
                    whiteSpace: "nowrap",
                    cursor: "pointer",
                    "@media (min-width: 1200px)": {
                      marginBlockEnd: 3,
                    },
                  }}
                >
                  <Text
                    sx={{
                      display: "inline-block",
                      borderBlockEnd: "1px solid transparent",
                      borderBlockEndColor:
                        currentHeading === heading.id && "currentColor",
                      transition: "border-block-end-color .2s",
                      pointerEvents: "none",
                    }}
                  >
                    {heading.title}
                  </Text>
                </Box>
              )
            })}
          </Box>
        </Box>
        <div>
          <ContentModules
            pullUp={page.isNavbarOverlapping}
            allModules={page.contentModules}
            shopstoryContent={pageContext.shopstoryContent}
          />
        </div>
      </Container>
    </Layout>
  )
}

export default ResponsibilityPageTemplate

export const pageQuery = graphql`
  query ResponsibilityPageQuery($slug: String!, $locale: String) {
    page: contentfulPage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      ...PageContent
    }
  }
`
